<template>
    <div class="d-flex justify-content-between mt-1 mb-1">
        <div class="d-flex w-100">
            <span class="mr-50 your-progress">
                {{ $t('media.your-progress') }}:
            </span>
            <b-progress :max="totalCompletableElements" show-value animated class="mr-50 completion-progress-bar">
            <b-progress-bar :value="completedElements" variant="success"></b-progress-bar>
            <b-progress-bar :value="inprogressElements" variant="warning"></b-progress-bar>
            <b-progress-bar :value="incompletedElements" variant="danger"></b-progress-bar>
            </b-progress>
            <span :title="completedElementsTitle">
                {{ completedElementsPercent }}%
            </span>
        </div>
    </div>
</template>
  
<script>
  
  export default {
    name: 'MediaProgressBar',
    props: {
      hasCompletable: { type: Boolean, default: false },
      elementsCompletion: { type: Object, default: {}, required: false },
    },
    data() {
      return {
        totalCompletableElements: 0,
        completedElements: 0,
        inprogressElements: 0,
        incompletedElements: 0,
        completedElementsPercent: 0,
        completedElementsTitle: 'Percentage of completed items',
      };
    },
    computed: {
    },
    async created() {
        this.buildProgressBar();
    },
    methods: {
      buildProgressBar() {
        //Fill in completed items for progress bar
        //console.log('elementsCompletion', this.elementsCompletion);
        this.totalCompletableElements = this.elementsCompletion.total;
        this.completedElements = this.elementsCompletion.completed;
        this.inprogressElements = this.elementsCompletion.inprogress;
        this.incompletedElements = this.elementsCompletion.uncompleted;
        this.completedElementsPercent = Number((this.completedElements / this.totalCompletableElements) * 100).toFixed(1);
        this.completedElementsTitle = this.completedElementsPercent + '% of completed items';
      },
    },
  };
  </script>
  <style scoped>
  .completion-progress-bar{
    width: 100%;
    height: 18px;
  }
  .your-progress{
    min-width: fit-content;
  }
  </style>
  