<template>
  <b-card class="w-100 text-center pt-4">
    <h3 class="mb-1">
      {{ $t('not-installed-app.title') }}
    </h3>
    <b-button
      v-if="canEdit"
      variant="primary"
      class="mb-2 mt-1 mr-50"
      @click="$router.push(routeData)"
    >
      {{ $t('not-installed-app.button-text') }}
    </b-button>
    <img :src="unenabledAppsPlaceholder" class="unenabled-app-image d-block mx-auto">
  </b-card>
</template>

<script>
import UnenabledAppsPlaceholder from '@/assets/images/placeholders/light/Background_empty.png';

export default {
  name: 'UninstalledAddon',
  props: {
    addonKey: {
      type: String,
      default: null,
    },
  },
  async created() {
    //console.log('this.addonKey', this.addonKey);
  },
  computed: {
    unenabledAppsPlaceholder() {
      return UnenabledAppsPlaceholder;
    },
    canEdit() {
      return this.$store.getters.currentCollective.isStaff;
    },
    routeData() {
      //console.log('routeData this.addonKey', this.addonKey);
      if(this.addonKey){
        //console.log({ name: 'addon', params: { id: this.addonKey }});
        return { name: 'addon', params: { id: this.addonKey }};
      }
      return { name: 'appstore' };
    }
  },
};
</script>

<style lang="scss" scoped>

.unenabled-app-image {
  max-width: 500px;
}
</style>
